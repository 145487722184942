import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { flowRight as compose } from 'lodash';
import { useLazyQuery } from '@apollo/client';

import VIOLATIONS_CHECKUP_QUERY from '../../../graphql/Dashboard/violations-checkup-query';
import VIOLATIONS_MESSAGE_QUERY from '../../../graphql/Dashboard/violations-message-query';
import VIOLATIONS_IMAGE_QUERY from '../../../graphql/Dashboard/violations-image-query';

import {
  TABS_VIOLATIONS,
  CHECKUP_VIOLATIONS_COLUMNS,
  MESSAGE_VIOLATIONS_COLUMNS,
  IMAGE_VIOLATIONS_COLUMNS
} from './util/dashboard-variables';
import { exportData } from './util/dashboard-util';

import AppBar from '@material-ui/core/AppBar';
import CommonTable from '../../common/CommonTable';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommonLoader from '../../common/CommonLoader';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { MenuItem, TextField } from '@material-ui/core';
import useUserStore from 'state/hooks/use-user-store';

const styles = theme => ({
  tabRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabAppbar: {
    backgroundColor: 'white',
    color: theme.palette.primary.main
  }
});

function TabContents({ isLoading, data, columns, exportName }) {
  return (
    <Typography component="div" style={{ padding: 20 }}>
      <Typography style={{ width: '100%' }} component="div">
        {isLoading && <CommonLoader />}
        {!isLoading && data && (
          <CommonTable
            options={{
              search: false,
              exportButton: true,
              pageSize: 10,
              exportCsv: (columns, data) => exportData(columns, data, exportName)
            }}
            columns={columns}
            data={data.map(o => ({
              ...o
            }))}
          />
        )}
      </Typography>
    </Typography>
  );
}

const DashboardViolations = props => {
  const [
    getCheckups,
    { loading: isCheckupViolationsLoading, data: checkupResponse }
  ] = useLazyQuery(VIOLATIONS_CHECKUP_QUERY, { fetchPolicy: 'network-only' });
  const [
    getMessages,
    { loading: isMessageViolationsLoading, data: messageResponse }
  ] = useLazyQuery(VIOLATIONS_MESSAGE_QUERY, { fetchPolicy: 'network-only' });
  const [
    getImages,
    { loading: isImageViolationsLoading, data: imageResponse }
  ] = useLazyQuery(VIOLATIONS_IMAGE_QUERY, { fetchPolicy: 'network-only' });
  const [{ me }] = useUserStore();
  const countries = me ? me.countries.map(o => ({ ...o })) : [];
  countries.sort((a, b) => (a.name < b.name ? -1 : 1));

  const [state, setState] = useState({
    tabValue: TABS_VIOLATIONS.CHECKUPS,
    countries: [],
    selectedCountryId: null
  } as any);

  const loadTabData = tabValue => {
    switch (tabValue) {
      case TABS_VIOLATIONS.CHECKUPS:
        getCheckups({ variables: { countryId: state.selectedCountryId } });
        break;
      case TABS_VIOLATIONS.MESSAGES:
        getMessages({ variables: { countryId: state.selectedCountryId } });
        break;
      case TABS_VIOLATIONS.IMAGES:
        getImages({ variables: { countryId: state.selectedCountryId } });
        break;
    }
  };

  const handleTabChange = (event, tabValue) => {
    loadTabData(tabValue);
    setState({ ...state, tabValue });
  };

  const handleExpansionPanelChange = (event: object, expanded: boolean) => {
    if (expanded) {
      loadTabData(state.tabValue);
    }
  };

  const handleCountryChange = e => {
    const selectedCountryId = e.target.value;

    if (selectedCountryId) {
      setState(s => {
        return { ...s, selectedCountryId };
      });
    }

    switch (state.tabValue) {
      case TABS_VIOLATIONS.CHECKUPS:
        getCheckups({ variables: { countryId: selectedCountryId } });
        break;
      case TABS_VIOLATIONS.MESSAGES:
        getMessages({ variables: { countryId: selectedCountryId } });
        break;
      case TABS_VIOLATIONS.IMAGES:
        getImages({ variables: { countryId: selectedCountryId } });
        break;
    }
  };

  const render = () => {
    const { classes = {} } = props;
    const { tabValue, selectedCountryId } = state;

    return (
      <div>
        <ExpansionPanel style={{ marginTop: 30 }} onChange={handleExpansionPanelChange}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" component="h3">
              Violations
            </Typography>
          </ExpansionPanelSummary>
          {countries && countries.length > 1 && (
            <div className="dashboard__country-select-wrapper">
              <TextField
                select
                disabled={!countries || !countries.length}
                value={selectedCountryId || ''}
                onChange={handleCountryChange}
                SelectProps={{ MenuProps: {} }}
                label="Country"
                margin="normal"
                className={`country-select${classes.textField ? ' ' + classes.textField : ''}`}
              >
                {countries?.map(x => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          )}
          <ExpansionPanelDetails>
            <Typography style={{ width: '100%' }} component="div">
              {
                <Paper>
                  <div className={classes.tabRoot}>
                    <AppBar className={classes.tabAppbar} position="static">
                      <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label="Checkup" />
                        <Tab label="Message" />
                        <Tab label="Image" />
                      </Tabs>
                    </AppBar>
                    {tabValue === TABS_VIOLATIONS.CHECKUPS && (
                      <TabContents
                        isLoading={isCheckupViolationsLoading}
                        data={checkupResponse && checkupResponse.childrenWithCheckupViolations}
                        columns={CHECKUP_VIOLATIONS_COLUMNS}
                        exportName="checkupViolations"
                      />
                    )}
                    {tabValue === TABS_VIOLATIONS.MESSAGES && (
                      <TabContents
                        isLoading={isMessageViolationsLoading}
                        data={messageResponse && messageResponse.childrenWithMessageViolations}
                        columns={MESSAGE_VIOLATIONS_COLUMNS}
                        exportName="messageViolations"
                      />
                    )}
                    {tabValue === TABS_VIOLATIONS.IMAGES && (
                      <TabContents
                        isLoading={isImageViolationsLoading}
                        data={imageResponse && imageResponse.childrenWithImageViolations}
                        columns={IMAGE_VIOLATIONS_COLUMNS}
                        exportName="imageViolations"
                      />
                    )}
                  </div>
                </Paper>
              }
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  };

  return render();
};

export default compose(withStyles(styles))(DashboardViolations);
