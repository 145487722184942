import RestApi from '../../../services/rest-api';
import AuthService from '../../../services/auth';
import { CONSTANT } from '../../../constant';
import 'whatwg-fetch';

const getResults = (params, { limit, type } = {} as any) => {
  return RestApi.get(`/reports/${type}`, {
    ...params,
    limit
  });
};

const downloadReport = async data => {
  const options: any = {
    body: JSON.stringify({ data }),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthService.getToken()}`
    }
  };
  fetch(CONSTANT.REST_URL + '/download/report', options)
    .then(res => res.blob())
    .then(blob => {
      const file = window.URL.createObjectURL(blob);
      window.location.assign(file);
    });
};

export default {
  getResults,
  downloadReport
};
